
::v-deep .el-table {
    overflow: auto;
    border: 1px solid #eee;
    .el-table__body-wrapper {
        .el-table__row {
            .ranking {
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-weight: 500;
                background-size: cover;
                display: inline-block;
            }
            &:first-child {
                .ranking {
                    background: url("../../../assets/images/student/num-001.png") no-repeat center;
                }
            }
            &:nth-of-type(2) {
                .ranking {
                    background: url("../../../assets/images/student/num-002.png") no-repeat center;
                }
            }
            &:nth-of-type(3) {
                .ranking {
                    background: url("../../../assets/images/student/num-003.png") no-repeat center;
                }
            }

        }
    }
}
.operation-pagination {
    padding: 0;
    margin: 30px 0;
    text-align: right;
    .el-pager {
        li.active {
            color: #FD4446;
        }
    }
    .el-pagination__sizes {
        .el-input__inner {
            color: #333;
        }
    }
    .el-pagination__total {
        color: #333;
    }
    .el-pagination__jump {
        color: #333;
    }
}
.link-blue-light {
    &.el-link--default {
        color: #1E63F1;
        &:hover {
            color: #6191f5;
        }
        &.is-disabled {
            &:hover {
                color: #C0C4CC;
            }
        }
    }
}
