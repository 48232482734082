
.goods-wrapper {
    .goods-box {
        display: flex;
        align-items: center;
        .goods-cover {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            border: 1px solid #eee;
            box-sizing: border-box;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-right {
            width: 1%;
            flex: 1;
            .goods-title {
                color: #333;
                line-height: 18px;
                transition: all .3s;
                &:hover {
                    /*color: #FD4446;*/
                }
            }

        }
    }
}
::v-deep .el-table {
     border: 1px solid #eee;
    .el-table__body-wrapper {
        .el-table__row {
            .ranking {
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-weight: 500;
                background-size: cover;
                display: inline-block;
            }
            &:first-child {
                .ranking {
                    background: url("../../../assets/images/student/num-001.png") no-repeat center;
                }
            }
            &:nth-of-type(2) {
                .ranking {
                    background: url("../../../assets/images/student/num-002.png") no-repeat center;
                }
            }
            &:nth-of-type(3) {
                .ranking {
                    background: url("../../../assets/images/student/num-003.png") no-repeat center;
                }
            }
        }
    }
}
.goodInfo-content {
    .info-item {
        margin-top: 20px;
        .item-title {
            font-size: 16px;
            line-height: 40px;
            background: #eee;
            color: #333;
            font-weight: bold;
            padding-left: 10px;
        }
        .item-content {
            margin-top: 10px;
            .attr-list {
                .item {
                    margin-top: 6px;
                }
            }
        }
    }
}
.operation-pagination {
    padding: 0;
    margin: 30px 0;
    text-align: right;
    .el-pager {
        li.active {
            color: #FD4446;
        }
    }
    .el-pagination__sizes {
        .el-input__inner {
            color: #333;
        }
    }
    .el-pagination__total {
        color: #333;
    }
    .el-pagination__jump {
        color: #333;
    }
}
.link-blue-light {
    &.el-link--default {
        color: #1E63F1;
        &:hover {
            color: #6191f5;
        }
        &.is-disabled {
            &:hover {
                color: #C0C4CC;
            }
        }
    }
}
